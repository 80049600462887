import { PropsWithChildren } from 'react';

interface ResponsiveMarginProps {
  left?: boolean;
  right?: boolean;
  atBreakpoints?: {
    xs?: boolean;
    s?: boolean;
    m?: boolean;
    l?: boolean;
    xl?: boolean;
  };
  variant?: 'default' | 'template';
}

/** @deprecated
 *  This is a legacy component, just use ResponsiveWrapper
 */
export const ResponsiveMargin: React.FC<
  PropsWithChildren<ResponsiveMarginProps>
> = ({
  children,
  left = true,
  right = true,
  atBreakpoints = { xs: true, s: true, m: true, l: true, xl: true },
  variant = 'default',
}) => {
  const { xs = true, s = true, m = true, l = true, xl = true } = atBreakpoints;

  if (variant === 'template') {
    return (
      <div
        className={`w-full ${xs && left ? 'pl-[20px]' : ''} ${xs && right ? 'pr-[20px]' : ''} ${m && left ? 'm:pl-[40px]' : ''} ${m && right ? 'm:pr-[40px]' : ''} ${l && left ? 'l:pl-[60px]' : ''} ${l && right ? 'l:pr-[60px]' : ''} ${xl && left ? 'xl:pl-[100px]' : ''} ${xl && right ? 'xl:pr-[100px]' : ''} `}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={`w-full ${xs && left ? 'pl-[16px]' : ''} ${xs && right ? 'pr-[16px]' : ''} ${s && left ? 's:pl-[24px]' : ''} ${s && right ? 's:pr-[24px]' : ''} ${m && left ? 'ml:pl-[40px]' : ''} ${m && right ? 'ml:pr-[40px]' : ''} ${l && left ? 'l:pl-[calc((100vw-1360px)/2)]' : ''} ${l && right ? 'l:pr-[calc((100vw-1360px)/2)]' : ''} `}
    >
      {children}
    </div>
  );
};
